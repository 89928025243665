import React from "react";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";

import {
  DateCreatedColumn,
  ParentClientColumn,
} from "../common";


const NoteTextColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  header: "Text",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["message_text"],
  accessorKey: "message_text",
  canSort: false,
  orderByField: "message_text",
  width: 300,
  dataTransform: ({ message_text }) => ({
    cell: <div dangerouslySetInnerHTML={{ __html: message_text }} />,
  }),
};

const NotesColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Notes> = {
  header: "Notes",
  type: BASE_COLUMN_TYPES.Notes,
  fields: ["message_id"],
  accessorKey: "notes",
  canSort: true,
  orderByField: "message_id",
  width: 64,
  dataTransform: ({ message_id }) => ({
    withNotes: true,
    href: `/message.php?id=${message_id}`,
  }),
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  {
    ...DateCreatedColumn,
    header: "Date",
  },
  ParentClientColumn,
  NoteTextColumn,
  NotesColumn,
];
