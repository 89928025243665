import { dateRangePickerFields } from "../../filters/components/DateRangePicker";
import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";
import { createLoadAddressList } from "../../../../actions/address";
import { createLoadCompanyContactList } from "../../../../actions/contact";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Project",
    fields: "job_name_or_number",
    type: FILTER_TYPES.Query,
    fetchReportOnEnterPress: true,
  },
  {
    label: "Order Date",
    type: FILTER_TYPES.DateRange,
    fields: dateRangePickerFields,
    defaultValue: ["this_year", "", ""],
  },
  {
    label: "Client Rep",
    type: FILTER_TYPES.User,
    fields: [
      "client_rep_id",
      "client_rep_teams",
      "include_inactive_client_reps",
    ],
  },
  {
    label: "Order Rep",
    type: FILTER_TYPES.User,
    fields: ["sales_rep_id", "sales_rep_teams", "include_inactive_order_reps"],
  },
  {
    label: "Form Type",
    type: FILTER_TYPES.FormType,
    fields: "form_type",
  },
  {
    label: "Order Status",
    type: FILTER_TYPES.OrderStatus,
    fields: "status_id",
    dependsOn: {
      filterFields: ["form_type"],
    },
  },
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_id",
  },
  {
    label: "Priority",
    type: FILTER_TYPES.Priority,
    fields: "priority",
    dependsOn: {
      filterFields: ["form_type"],
      forOccurence: true,
      parentValue: ["OPPORTUNITY"],
    },
  },
  {
    label: "Order Tag",
    type: FILTER_TYPES.OrderTag,
    fields: "order_tag",
  },
  {
    label: "Client Tag",
    type: FILTER_TYPES.ClientTag,
    fields: "client_tag",
  },
  {
    label: "Client Status",
    type: FILTER_TYPES.AccountStatus,
    fields: "account_status_id",
    dropdownOptionsFilter: {
      company_type: "CLIENT",
    },
  },
  {
    label: "Billing Address",
    type: FILTER_TYPES.BillingAddress,
    fields: "billing_address_id",
    dependsOn: {
      filterFields: ["client_id"],
      forOccurence: true,
      dataLoader: (client_id: string) =>
        createLoadAddressList(client_id, "CLIENT"),
    },
  },
  {
    label: "Shipping Address",
    type: FILTER_TYPES.ShippingAddress,
    fields: "shipping_address_id",
    dependsOn: {
      filterFields: ["client_id"],
      forOccurence: true,
    },
  },
  {
    label: "Client Contact",
    type: FILTER_TYPES.ClientContact,
    fields: "client_user_id",
    dependsOn: {
      filterFields: ["client_id"],
      forOccurence: true,
      dataLoader: (client_id: string) =>
        createLoadCompanyContactList(client_id, "CLIENT"),
    },
  },
  {
    label: "Industry",
    type: FILTER_TYPES.Industry,
    fields: "industry_id",
  },
  {
    label: "Event Type",
    type: FILTER_TYPES.EventType,
    fields: "event_type_id",
  },
];
