import React, { useMemo } from "react";
import { SelectedRowsComponentProps } from "../index";
import { Button, Typography } from "@commonsku/styles";
import { dataToUrlParams, getIdentityUtils, oauth } from "../../../../utils";
import { useIdentity } from "../../../../hooks";
import { window } from "../../../../global";

const btnContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  flex: "none",
  order: 0,
  flexGrow: 0,
};

const SelectedRowsBar = ({
  selectedRows,
  onClearSelection,
  params = {},
}: SelectedRowsComponentProps<{
  commission_id: { commission_id: string; commission_paid: number | string };
}>) => {
  const identity = useIdentity();
  const { hasCapabilities } = getIdentityUtils(identity);
  const allPaid = useMemo(
    () =>
      selectedRows.filter((v) => +v.commission_id.commission_paid === 1)
        .length === selectedRows.length,
    [selectedRows],
  );
  const allUnPaid = useMemo(
    () =>
      selectedRows.filter((v) => +v.commission_id.commission_paid !== 1)
        .length === selectedRows.length,
    [selectedRows],
  );
  const canModify = hasCapabilities("MODIFY-COMMISSION");

  const onClickPay = () => {
    if (allPaid) {
      return;
    }
    const commission_ids = selectedRows
      .filter((v) => +v.commission_id.commission_paid !== 1)
      .map((v) => v.commission_id.commission_id);
    window.open(
      window.location.origin +
        "/pay_commission.php?" +
        dataToUrlParams({ commission_ids }),
      "_blank",
    );
    onClearSelection();
  };

  const onClickUnPay = () => {
    if (allUnPaid) {
      return;
    }
    const commission_ids = selectedRows
      .filter((v) => +v.commission_id.commission_paid === 1)
      .map((v) => v.commission_id.commission_id);
    oauth("POST", "commission", {
      action: "unpay",
      id: commission_ids,
    });
    onClearSelection();
  };

  const onClickExport = () => {
    if (allUnPaid) {
      return;
    }
    const data = {
      ...params,
      report_type: "commission",
      csku_report: false,

      "start-index": "",
      "max-results": "",
      order_by: "",
      order_dir: "",
    };
    window.open(
      window.location.origin + "/export_report.php?" + dataToUrlParams(data),
      "_blank",
    );
    onClearSelection();
  };

  if (selectedRows.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "8px 32px",
        gap: "8px",
        position: "fixed",
        width: "598px",
        height: "90px",
        left: "auto",
        right: "auto",
        top: "auto",
        bottom: 0,
        margin: "auto auto 0px",
        marginTop: "12px",
        marginLeft: "calc(50% + 90px)",
        transform: "translateX(-50%)",
        background: "var(--color-teal-90)",
        borderRadius: "8px 8px 0px 0px",
      }}
    >
      <Typography.Text
        bold
        style={{
          width: "233px",
          height: "74px",
          fontStyle: "normal",
          fontSize: "18px",
          lineHeight: "24px",
          display: "flex",
          alignItems: "center",
          color: "#FFFFFF",
          flex: "none",
          order: 0,
          flexGrow: 0,
        }}
      >
        {selectedRows.length} commissions selected
      </Typography.Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "0px",
          gap: "8px",
          width: "293px",
          height: "48px",
          flex: "none",
          order: 1,
          flexGrow: 0,
        }}
      >
        {canModify && (
          <div style={btnContainerStyle}>
            <Button
              onClick={onClickPay}
              style={{ width: "100%" }}
              disabled={allPaid}
              size="medium"
            >
              Pay
            </Button>
          </div>
        )}
        {canModify && (
          <div style={btnContainerStyle}>
            <Button
              onClick={onClickUnPay}
              style={{ width: "100%" }}
              disabled={allUnPaid}
              size="medium"
            >
              Un-Pay
            </Button>
          </div>
        )}
        {hasCapabilities("EXPORT-REPORT") && (
          <div style={btnContainerStyle}>
            <Button
              onClick={onClickExport}
              style={{ width: "100%" }}
              disabled={allUnPaid}
              size="medium"
            >
              Export
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectedRowsBar;
