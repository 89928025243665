import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { EReportTypes } from "../../routes/report_types";

const RepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id",
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 100,
  avatarOptions: { withFullName: true },
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

const OpportunityQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "Opporunities",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_opportunities"],
  accessorKey: "num_opportunities",
  canSort: true,
  orderByField: "num_opportunities",
  width: 50,
  dataTransform: ({ num_opportunities }) => ({
    amount: num_opportunities,
  }),
  subReportType: EReportTypes.DashboardOpportunity,
};

const PresentationQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "Presentations",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_presentations"],
  accessorKey: "num_presentations",
  canSort: true,
  orderByField: "num_presentations",
  width: 50,
  dataTransform: ({ num_presentations }) => ({
    amount: num_presentations,
  }),
  subReportType: EReportTypes.DashboardPresentation,
};

const EstimateQuantityColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Quantity
> = {
  header: "Estimates",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["num_estimates"],
  accessorKey: "num_estimates",
  canSort: true,
  orderByField: "num_estimates",
  width: 50,
  dataTransform: ({ num_estimates }) => ({
    amount: num_estimates,
  }),
  subReportType: EReportTypes.DashboardEstimate,
};

const OpportunityBudgetColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Total Opportunity Budget",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_opportunity_budget"],
  accessorKey: "total_opportunity_budget",
  canSort: true,
  orderByField: "total_opportunity_budget",
  moneyOptions: { decimalPlaces: 2 },
  width: 75,
  dataTransform: ({ total_opportunity_budget, currency_id }) => ({
    amount: total_opportunity_budget,
    currency_id,
  }),
  subReportType: EReportTypes.DashboardOpportunity,
};

const PresentationBudgetColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Total Presentation Budget",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_presentation_budget"],
  accessorKey: "total_presentation_budget",
  canSort: true,
  orderByField: "total_presentation_budget",
  moneyOptions: { decimalPlaces: 2 },
  width: 75,
  dataTransform: ({ total_presentation_budget, currency_id }) => ({
    amount: total_presentation_budget,
    currency_id,
  }),
  subReportType: EReportTypes.DashboardPresentation,
};

const EstimateBudgetColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Total Estimate Budget",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_estimate_budget"],
  accessorKey: "total_estimate_budget",
  canSort: true,
  orderByField: "total_estimate_budget",
  moneyOptions: { decimalPlaces: 2 },
  width: 75,
  dataTransform: ({ total_estimate_budget, currency_id }) => ({
    amount: total_estimate_budget,
    currency_id,
  }),
  subReportType: EReportTypes.DashboardEstimate,
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  RepColumn,
  OpportunityQuantityColumn,
  PresentationQuantityColumn,
  EstimateQuantityColumn,
  OpportunityBudgetColumn,
  PresentationBudgetColumn,
  EstimateBudgetColumn,
];
