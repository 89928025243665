import { ReportSummaryConfigBase } from "../../summary/types";

export const Summary: ReportSummaryConfigBase[] = [
  {
    label: "Number of Rows",
    fields: ["row_count"],
    type: ["COUNT"],
  },
  {
    label: "# Opportunities",
    fields: ["opportunity_count"],
    type: ["COUNT"],
  },
  {
    label: "# Presentations",
    fields: ["presentation_count"],
    type: ["COUNT"],
  },
  {
    label: "# Estimates",
    fields: ["estimate_count"],
    type: ["COUNT"],
  },
  {
    label: "# Notes",
    fields: ["num_notes"],
    type: ["COUNT"],
  },
  {
    label: "# Calls",
    fields: ["num_calls"],
    type: ["COUNT"],
  },
  {
    label: "# Meetings",
    fields: ["num_meetings"],
    type: ["COUNT"],
  },
  {
    label: "Dates",
    fields: ["start_stamp", "end_stamp"],
    type: ["HIDDEN", "HIDDEN"],
  },
];
