import ReactDOM from "react-dom";
import React, { useState } from "react";
import { Csku, EditIcon, HelpIcon } from "@commonsku/styles";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { formatDateFromString, getIdentityUtils } from "../../../../utils";
import { useIdentity } from "../../../../hooks";
import { EReportTypes } from "../../routes/report_types";
import ReactTooltip from "../../../helpers/ReactTooltip";
import CommissionExplanation, {
  CommissionExplanationProps,
} from "./CommissionExplanation";
import OverrideCommissionPopup from "./OverrideCommissionPopup";

const root =
  document.getElementById("root") || document.getElementById("resku");

const ORepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_user_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id",
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 73,
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

const FormColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "ID",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["job_number"],
  accessorKey: "job_number",
  canSort: true,
  orderByField: "job_number",
  width: 83,
  dataTransform: ({ job_number }) => ({ text: `#${job_number}` }),
};

const ProjectColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> = {
  header: "Name",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_name", "job_number"],
  accessorKey: "job",
  canSort: true,
  orderByField: "job_name",
  width: 191,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ job_name, job_number }) => ({
    href: `/project/${job_number}`,
    text: job_name,
  }),
};

const ClientColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> = {
  header: "Client",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["client_id", "client_name"],
  accessorKey: "client",
  canSort: true,
  orderByField: "client_name",
  width: 150,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ client_id, client_name }) => ({
    href: `/client.php?id=${client_id}`,
    text: client_name,
  }),
};

const InvoicesColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Invoices",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["invoice_form_numbers"],
  accessorKey: "invoice_form_numbers",
  canSort: true,
  orderByField: "invoice_form_numbers",
  width: 105,
  dataTransform: ({ invoice_form_numbers }) => {
    const invoices = invoice_form_numbers.split(";");
    return {
      text: `${invoices[0]}${invoices.length > 1 ? "+" : ""}`,
      tooltipText: invoices.join(", "),
    };
  },
  subReportType: EReportTypes.CommissionInvoice,
};

const SubtotalColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Subtotal",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["margin_subtotal", "currency_id", "job_id"],
  accessorKey: "margin_subtotal",
  canSort: true,
  orderByField: "margin_subtotal",
  width: 105,
  textOptions: { textColor: "var(--color-primary1-main)" },
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ margin_subtotal, currency_id }) => ({
    amount: margin_subtotal,
    currency_id,
  }),
  subReportType: EReportTypes.CommissionInvoice,
};

const CostsColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Costs",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["margin_costs", "order_id"],
  accessorKey: "margin_costs",
  canSort: true,
  orderByField: "margin_costs",
  width: 105,
  textOptions: { textColor: "var(--color-primary1-main)" },
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ margin_costs, currency_id }) => ({
    amount: margin_costs,
    currency_id: currency_id,
  }),
  subReportType: EReportTypes.CommissionBill,
};

const MarginColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Margin> = {
  header: "Margin",
  type: BASE_COLUMN_TYPES.Margin,
  fields: ["total_margin_amount", "total_margin", "currency_id"],
  accessorKey: "total_margin_amount",
  canSort: true,
  orderByField: "total_margin_amount",
  width: 105,
};

const CommissionColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Commission
> = {
  header: "Commission",
  type: BASE_COLUMN_TYPES.Commission,
  fields: [
    "commission_amount",
    "currency_id",
    "commission_override",
    "estimated",
  ],
  accessorKey: "commission_amount",
  canSort: true,
  orderByField: "commission_amount",
  width: 105,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({
    commission_amount,
    currency_id,
    commission_override,
    estimated,
  }) => ({
    commission_override: +commission_override === 1,
    estimated: +estimated,
    amount: commission_amount,
    currency_id,
  }),
};

const InvoiceDateColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Date> =
  {
    header: "Invoice Date",
    type: BASE_COLUMN_TYPES.Date,
    fields: ["invoice_date"],
    accessorKey: "invoice_date",
    canSort: true,
    orderByField: "invoice_date",
    width: 120,
    textOptions: { textColor: "var(--color-primary1-main)" },
    subReportType: EReportTypes.CommissionInvoice,
  };

const InvoicePaidDateColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Invoice Paid",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["invoice_date_paid"],
  accessorKey: "invoice_date_paid",
  canSort: true,
  orderByField: "invoice_date_paid",
  width: 120,
  textOptions: { textColor: "var(--color-primary1-main)" },
  subReportType: EReportTypes.CommissionInvoice,
};

const CommissionPaidDateColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Com Paid",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_commission_paid"],
  accessorKey: "date_commission_paid",
  canSort: true,
  orderByField: "date_commission_paid",
  width: 120,
  dataTransform: ({ date_commission_paid }) => {
    return {
      date: formatDateFromString(date_commission_paid, { dateStyle: "long" }),
    };
  },
};

const EditCommission = ({ commission_id, commission_paid }) => {
  const identity = useIdentity();
  const { hasCapabilities } = getIdentityUtils(identity);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  if (1 === commission_paid || !hasCapabilities("MODIFY-COMMISSION")) {
    return null;
  }
  return (
    <>
      {showPopup && (
        <OverrideCommissionPopup
          commissionId={commission_id}
          onClose={() => setShowPopup(false)}
        />
      )}
      <EditIcon
        style={{ cursor: "pointer" }}
        onClick={() => setShowPopup(true)}
      />
    </>
  );
};

const EditCommissionColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  header: "",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["commission_id", "commission_paid"],
  accessorKey: "commission_id",
  canSort: false,
  width: 50,
  orderByField: "commission_id",
  dataTransform: ({ commission_id, commission_paid }) => {
    return {
      cell: (
        <EditCommission
          commission_id={commission_id}
          commission_paid={commission_paid}
        />
      ),
    };
  },
};

const ExplanationColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  type: BASE_COLUMN_TYPES.Generic,
  header: "",
  fields: [
    "commission_id",
    "estimated",
    "commission_source",
    "base_amount",
    "commission_type",
    "base_rate",
    "margin",
    "margin_multiplier",
    "sales_ytd",
    "sales_target_multiplier",
    "client_rate",
    "client_multiplier",
    "initial_amount",
    "commission_amount",
    "commission_override",
    "has_breakdown",
  ],
  accessorKey: "explanation",
  canSort: false,
  orderByField: "has_breakdown",
  width: 50,
  dataTransform: (commission) => {
    return {
      cell: !!commission.has_breakdown && (
        <Csku>
          <HelpIcon
            style={{ cursor: "pointer" }}
            data-tip
            data-for={`tooltip-commission-explanation-${commission.commission_id}`}
          />
          {ReactDOM.createPortal(
            <ReactTooltip
              id={`tooltip-commission-explanation-${commission.commission_id}`}
              type="dark"
              place="left"
            >
              <CommissionExplanation
                {...(commission as unknown as CommissionExplanationProps)}
              />
            </ReactTooltip>,
            root,
          )}
        </Csku>
      ),
    };
  },
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ORepColumn,
  FormColumn,
  ProjectColumn,
  ClientColumn,
  InvoicesColumn,
  SubtotalColumn,
  CostsColumn,
  MarginColumn,
  CommissionColumn,
  InvoiceDateColumn,
  InvoicePaidDateColumn,
  CommissionPaidDateColumn,
  EditCommissionColumn,
  ExplanationColumn,
];
