import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  Context,
} from "react";
import { ReportConfig, STYLE_CONSTANTS, ReportElementSizes } from "./config";
import {
  FilterOnChange,
  ReportFilterFields,
  ReportFilterValues,
} from "./filters/config/types";
import { useDispatch } from "react-redux";
import { oauth } from "../../utils";
import { mapSummaryDataByConfig, ReportSummaryData } from "./summary/types";
import {
  mapRowDataByConfig,
  ReportRow,
} from "./columns/config/ReportColumnConfig";
import { ReportTypeValues } from "./routes/report_types";
import { rebuildTooltip } from "../helpers";
import { ReportFilterConfig } from "./filters/config/ReportFilterConfig";
import SubReportPopup from "./sub-report/SubReportPopup";

interface IReportContext {
  reportConfig: ReportConfig;
  query: {};
  prevQuery: {};
  onFilterChange: FilterOnChange;
  fetchReport: () => void;
  summaryData?: ReportSummaryData[];
  rowData: ReportRow[];
  activeRow?: ReportRow;
  loading: boolean;
  firstLoaded: boolean;
  tableHeight: number;
  sort: Sort;
  pageNo: number;
  pageSize: number;
  setPageNumber: (page: number) => void;
  totalRows: number;
  sortByColumn: (orderBy: string, orderDir: string) => void;
  getRawQuery: (extraOptions?: {}) => Record<string, string>;
  selectedRows?: Record<string, unknown>[];
  onSelectionChange?: (rows: Record<string, unknown>[]) => void;
  onClearSelection: () => void;
  setActiveRow: (activeRow: ReportRow) => void;
  setSubReportType: (subReportType: ReportTypeValues) => void;
  resetQuery: (filers?: ReportFilterConfig[]) => void;
  elementSizeParameters: ReportElementSizes;
}

interface Sort {
  orderBy: string;
  orderDir: string;
}

interface FetchReportResponse {
  json: {};
  response: Response;
}

const DEFAULT_PAGE_SIZE = 50;

const ReportContext: Context<IReportContext> =
  createContext<IReportContext>(null);

interface ReportProviderProps {
  reportConfig: ReportConfig;
  defaultFilters: {};
  elementSizeParameters?: ReportElementSizes;
  containerHeight?: number;
  children: React.ReactNode;
}

function getBaseQuery(query, extraOptions = {}) {
  return Object.entries(query).reduce(
    (acc, [key, value]) => {
      if (value) {
        return { ...acc, [key]: value };
      }
      return acc;
    },
    { ...extraOptions },
  );
}

export const ReportProvider = ({
  reportConfig,
  defaultFilters,
  elementSizeParameters = STYLE_CONSTANTS,
  containerHeight,
  children,
}: ReportProviderProps) => {
  const dispatch = useDispatch();

  const [query, setQuery] = useState(
    () => defaultFilters ?? reportConfig?.filters?.initializeReportQuery(),
  );

  const [prevQuery, setPrevQuery] = useState(() => ({}));
  const [selectedRows, setSelectedRows] = useState<Record<string, unknown>[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [firstLoaded, setFirstLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [summaryData, setSummaryData] = useState<ReportSummaryData[]>([]);
  const [rowData, setRowData] = useState<ReportRow[]>([]);
  const [pageNo, setPageNo] = useState<number>(0);
  const pageSize = reportConfig?.resultsPerPage || DEFAULT_PAGE_SIZE;
  const [totalRows, setTotalRows] = useState<number>(0);
  const [tableHeight, setTableHeight] = useState<number>(0);
  const abortController = useRef<AbortController>(null);
  const [prevTableHeight, setPrevTableHeight] = useState<number>(0);
  const [sort, setSort] = useState<Sort>({
    orderBy: "",
    orderDir: "",
  });
  const [subReportType, setSubReportType] = useState<
    ReportTypeValues | undefined
  >();
  const [activeRow, setActiveRow] = useState<ReportRow | undefined>();

  const fitableRows = useMemo(() => {
    let height =
      containerHeight -
      elementSizeParameters.FILTER_CONTAINER_HEIGHT -
      elementSizeParameters.TABLE_PAGE_HEADER_HEIGHT -
      elementSizeParameters.TABLE_BOTTOM_MARGIN -
      elementSizeParameters.TABLE_ELEMENTS_GAP * 2;
    if (reportConfig.summary?.length > 0) {
      height -= elementSizeParameters.SUMMARY_CONTAINER_HEIGHT;
    }

    return {
      numberOfRows: Math.floor(height / elementSizeParameters.ROW_HEIGHT),
      height,
    };
  }, [reportConfig.summary?.length, containerHeight, elementSizeParameters]);

  useEffect(() => {
    const rowsInPage = rowData.length;
    const { numberOfRows, height } = fitableRows;

    let tableHeight: number;

    if (rowsInPage < numberOfRows) {
      tableHeight = height;
    } else {
      tableHeight = rowsInPage * elementSizeParameters.ROW_HEIGHT;
    }

    setTableHeight(tableHeight);
    setPrevTableHeight(tableHeight);
    rebuildTooltip();
  }, [
    elementSizeParameters.ROW_HEIGHT,
    fitableRows,
    pageSize,
    reportConfig.summary.length,
    rowData.length,
  ]);

  const getRawQuery = useCallback(
    () => getBaseQuery(query),
    [query],
  );

  const getQuery = useCallback(
    (query: {}, page: number = 0, orderBy?: string, orderDir?: string) =>
        getBaseQuery(
          query,
          {
            "max-results": pageSize,
            ...(page > 0 ? { "start-index": page * pageSize } : {}),
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(orderDir ? { order_dir: orderDir } : {}),
          }
        ),
      [pageSize],
  );

  const baseFetchReport = useCallback(
    async (reportQuery: {}) => {
      const errorHandler = (
        e?: Error | string,
        stopLoading: boolean = false,
      ) => {
        e && console.log("Error fetching report", e);
        stopLoading && setLoading(false);
        setError("Error fetching report");
      };

      if (abortController.current) {
        abortController.current.abort();
      }
      abortController.current = new AbortController();

      setLoading(true);
      setSelectedRows([]);
      setActiveRow(undefined);

      let res: FetchReportResponse;

      try {
        res = await oauth(
          "GET",
          reportConfig.endpoint,
          reportQuery,
          undefined,
          { signal: abortController.current.signal },
        );
      } catch (e) {
        errorHandler(e, e.name !== "AbortError");
        return;
      }

      if (!res.json) {
        errorHandler("Empty response");
        return;
      }

      const { json } = res;

      setPrevQuery(reportQuery);
      setSummaryData(() =>
        mapSummaryDataByConfig(
          json[reportConfig.summaryKey],
          reportConfig.summary,
        ),
      );
      setRowData(() =>
        mapRowDataByConfig(json[reportConfig.reportKey], reportConfig.columns),
      );
      setTotalRows(
        parseInt(json[reportConfig.summaryKey]?.row_count as string) || 0,
      );
      setFirstLoaded(true);
      setLoading(false);
      rebuildTooltip();
    },
    [
      reportConfig.summaryKey,
      reportConfig.endpoint,
      reportConfig.summary,
      reportConfig.reportKey,
      reportConfig.columns,
    ],
  );

  const fetchReport = useCallback(
    async () => {
      const reportQuery = getQuery(query, pageNo, sort.orderBy, sort.orderDir);
      baseFetchReport(reportQuery);
    },
    [baseFetchReport, query, pageNo, sort.orderBy, sort.orderDir],
  );

  useEffect(() => {
    if (defaultFilters) {
      fetchReport();
    }
  }, [defaultFilters]);

  const refetchOnFilterChange = useCallback((query) => {
    try {
      const reportQuery = getQuery(query, pageNo, sort.orderBy, sort.orderDir);
      baseFetchReport(reportQuery);
    } catch (e) {
      console.log("Error fetching report", e);
    }
  }, [pageNo, sort.orderBy, sort.orderDir, baseFetchReport]);

  const onFilterChange: FilterOnChange = useCallback(
    (fields: ReportFilterFields, values: ReportFilterValues) => {
      const newQuery =
        typeof fields === "string"
          ? { ...query, [fields]: values }
          : Array.isArray(fields)
            ? fields.reduce(
                (acc, field, index) => ({
                  ...acc,
                  [field]: values[index],
                }),
                { ...query },
              )
            : query;
      setQuery(newQuery);

      const dependentFields = reportConfig.filters.dependentFields;
      let hasDependentFields: boolean;
      if (Array.isArray(fields)) {
        hasDependentFields = fields.some((field) =>
          dependentFields.has(field),
        );
      } else {
        hasDependentFields = dependentFields.has(fields);
      }
      if (hasDependentFields) {
        const dependentQuery = Array.from(dependentFields).reduce(
          (acc, field) => ({
            ...acc,
            ...(query[field] !== newQuery[field]
              ? { [field]: newQuery[field] }
              : {}),
          }),
          {},
        );
        reportConfig.filters.loadDataForDependentFilters(
          dependentQuery,
          dispatch,
        );
      }

      if (reportConfig.refetchOnFilterChange) {
        const reportQuery = getQuery(newQuery, 0, sort.orderBy, sort.orderDir)
        setPageNo(0);
        baseFetchReport(reportQuery).catch(console.log);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    [dispatch, reportConfig.filters, reportConfig.refetchOnFilterChange, baseFetchReport, query, getQuery, sort.orderBy, sort.orderDir],
  );

  const setPageNumber = useCallback(
    async (page: number) => {
      if (page !== pageNo) {
        const reportQuery = getQuery(query, page, sort.orderBy, sort.orderDir);
        setPageNo(page);
        baseFetchReport(reportQuery).catch(console.log);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    [fetchReport, pageNo, sort.orderBy, sort.orderDir],
  );

  const sortByColumn = useCallback(
    (orderBy: string, orderDir: string) => {
      const reportQuery = getQuery(query, 0, orderBy, orderDir);
      setPageNo(0);
      setSort({ orderBy, orderDir });
      baseFetchReport(reportQuery).catch(console.log);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [fetchReport],
  );

  const onSelectionChange = useCallback(
    (rows: Record<string, unknown>[]) => {
      setTableHeight(
        rows.length === 0 ? prevTableHeight : prevTableHeight - 100,
      );
      setSelectedRows(rows);
      rebuildTooltip();
    },
    [prevTableHeight],
  );

  const onClearSelection = useCallback(() => {
    setTableHeight(prevTableHeight);
    setSelectedRows([]);
    rebuildTooltip();
  }, [prevTableHeight]);

  const resetQuery = useCallback(
    (filters?: ReportFilterConfig[]) => {
      setPrevQuery({});
      const newQuery = {
        ...prevQuery,
        ...reportConfig.filters.initializeReportQuery(filters, false),
      };

      setQuery(newQuery);

      if (reportConfig.refetchOnFilterChange) {
        refetchOnFilterChange(newQuery);
      }
    },
    [reportConfig.filters, refetchOnFilterChange],
  );

  const value: IReportContext = useMemo(
    () => ({
      reportConfig,
      query,
      prevQuery,
      onFilterChange,
      fetchReport,
      summaryData,
      rowData,
      activeRow,
      loading,
      firstLoaded,
      tableHeight,
      pageNo,
      pageSize,
      setPageNumber,
      totalRows,
      sortByColumn,
      getRawQuery,
      selectedRows,
      onSelectionChange,
      onClearSelection,
      resetQuery,
      setActiveRow,
      setSubReportType,
      sort,
      elementSizeParameters,
    }),
    [
      reportConfig,
      query,
      prevQuery,
      onFilterChange,
      fetchReport,
      summaryData,
      rowData,
      activeRow,
      loading,
      firstLoaded,
      tableHeight,
      pageNo,
      pageSize,
      setPageNumber,
      totalRows,
      sortByColumn,
      getRawQuery,
      selectedRows,
      onSelectionChange,
      onClearSelection,
      resetQuery,
      sort,
      elementSizeParameters,
    ],
  );

  const subReport = useMemo(() => {
    if (!subReportType || !activeRow) {
      return null;
    }
    const popupTitle = reportConfig.subReports.getTitle(activeRow);

    return (
      <SubReportPopup
        title={popupTitle}
        subReportType={subReportType}
        onClose={() => {
          setSubReportType(undefined);
          setActiveRow(undefined);
        }}
      />
    );
  }, [reportConfig.subReports, subReportType, activeRow]);

  return (
    <ReportContext.Provider value={value}>
      {subReport}
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = (): IReportContext => {
  const context = useContext(ReportContext);

  if (!context) {
    throw new Error("useReportContext must be used within a ReportProvider");
  }

  return context;
};
