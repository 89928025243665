import { ReportSummaryConfigBase } from "../../summary/types";

export const Summary: ReportSummaryConfigBase[] = [
  {
    label: "Total",
    fields: ["total_subtotal"],
    type: ["MONEY"],
  },
  {
    label: "Booked Margin Total",
    fields: ["total_booked_margin_amount"],
    type: ["MONEY"],
  },
  {
    label: "Booked Margin Average",
    fields: ["average_booked_margin_amount", "average_booked_margin"],
    type: ["MONEY", "PERCENTAGE"],
  },
  {
    label: "Billed Margin Total",
    fields: ["total_billed_margin_amount"],
    type: ["MONEY"],
  },
  {
    label: "Billed Margin Average",
    fields: ["average_billed_margin_amount", "average_billed_margin"],
    type: ["MONEY", "PERCENTAGE"],
  },
  {
    label: "Total Budget",
    fields: ["total_budget"],
    type: ["MONEY"],
  },
  {
    label: "Number of Rows",
    fields: ["row_count"],
    type: ["COUNT"],
  },
];
