import React from "react";

export const FILTER_TYPES = {
  Query: "QUERY",
  User: "USER",
  Client: "CLIENT",
  Supplier: "SUPPLIER",
  SupplierTag: "SUPPLIER_TAG",
  AccountStatus: "ACCOUNT_STATUS",
  CompareTo: "COMPARETO",
  OrderStatus: "ORDER_STATUS",
  FormType: "FORM_TYPE",
  OrderTag: "ORDER_TAG",
  ClientTag: "CLIENT_TAG",
  BillingAddress: "BILLING_ADDRESS",
  ShippingAddress: "SHIPPING_ADDRESS",
  ClientContact: "CLIENT_CONTACT",
  Industry: "INDUSTRY",
  EventType: "EVENT_TYPE",
  Date: "DATE",
  Dropdown: "DROPDOWN",
  Switch: "SWITCH",
  DateRange: "DATE_RANGE",
  Priority: "PRIORITY",
  Country: "COUNTRY",
  Province: "PROVINCE",
  Department: "DEPARTMENT",
  ContactTag: "CONTACT_TAG",
  ClientPageProjectQuery: "CLIENT_PAGE_PROJECT_QUERY",
  Checkbox: "CHECKBOX",
} as const;

export type FilterTypeKeys = keyof typeof FILTER_TYPES;
export type FilterTypeValues = (typeof FILTER_TYPES)[FilterTypeKeys];

export type ReportFilterFields = string | string[];
export type ReportFilterValues =
  | string
  | number
  | string[]
  | number[]
  | (string | number | string[])[];

export type FilterOnChange = (
  fields: ReportFilterFields,
  values: ReportFilterValues,
) => void;

export interface StyleProps {
  flexBasis: number;
  flexShrink: number;
  flexGrow: number;
}

export type DataLoader = (...args: any[]) => (dispatch: any) => Promise<any>;

export interface Dependency {
  filterFields: string[]; // fields that the filter depends on
  forOccurence?: boolean; // only appear if at least one of the depended fields has a value
  parentValue?: string[]; // what value of the depended field should trigger the appearance filter
  dataLoader?: DataLoader; // redux action to fetch data for the filter
}

export interface ReportFilterConfigBase {
  label: string;
  type: FilterTypeValues;
  fields: ReportFilterFields;
  placeholder?: string;
  dependsOn?: Dependency;
  defaultValue?: ReportFilterValues;
  options?: (string | { label: string; value: string })[];
  flexBasis?: number;
  containerStyle?: React.CSSProperties;
  fetchReportOnEnterPress?: boolean;
  dropdownOptionsFilter?: {[key: string]: string}
  showLabel?: boolean;
}
