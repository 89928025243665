import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";
import SelectedRowsBar from "./SelectedRowsBar";
import { bill, invoice } from "./sub-reports";

type TCommissionRow = {
  values: {
    commission_id: { commission_id: string; commission_paid: number | string };
  };
};

const CommissionsReportConfig: ReportConfigBase<TCommissionRow["values"]> = {
  type: EReportTypes.Commission,
  endpoint: "report/commission",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: true,
  intro: {
    description:
      "The Commission Report offers various reporting options, like rep, rep type, client, invoice/paid date range, paid or unpaid status on invoices, bills and commissions",
    url: "https://help.commonsku.com/knowledge/commission-report",
  },
  SelectedRowsComponent: SelectedRowsBar,
  isRowSelected(selectedRows, row) {
    const id = row?.original?.commission_id?.commission_id;
    if (!id) return false;
    const found = selectedRows.find(
      (r) =>
        row.original.commission_id.commission_id ===
        r.commission_id.commission_id,
    );
    return Boolean(found);
  },
  subReports: {
    configs: [bill, invoice],
    titleTemplate: (rowData: Record<string, any>) =>
      `Project #${rowData.job.job_number}`,
  },
};

export default CommissionsReportConfig;
